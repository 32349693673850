import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import authService from "./services/auth.service";
import { useState } from "react";
import { AuthInfo, AuthProvider } from "./context/auth";
import { Home } from "./pages/Home";
import { Auth } from "./pages/Auth";
import { Layout } from "./pages/components/Layout";
import { Channel } from "./pages/Channel";
import { Library } from "./pages/Library";

function App() {
  const returnTo = window.location.pathname;

  const [authState, setAuthState] = useState<AuthInfo>({
    isLoggedIn: authService.isSignedIn(),
  });

  const onLoginSuccess = (authInfo: AuthInfo, loginReturnTo?: string) => {
    setAuthState(authInfo);
    if (loginReturnTo) {
      window.location.pathname = loginReturnTo;
    }
  };
  const onLogout = () => {
    authService.signOut();
    setAuthState({
      isLoggedIn: false,
    });
  };

  const onUpdate = (
    firstName: string,
    lastName: string,
    email: string,
  ) => {
    setAuthState({
      isLoggedIn: authState.isLoggedIn,
      firstName,
      lastName,
      email,
    });
  };

  return (
    <AuthProvider
      value={{ authInfo: authState, onLoginSuccess, onLogout, onUpdate }}
    >
      <Router>
        <div>
          {authState.isLoggedIn && (
            <Layout>
              <Switch>
                <Route path="/channel">
                  <Channel />
                </Route>
                <Route path="/library">
                  <Library />
                </Route>
                <Redirect to="/channel" />
              </Switch>
            </Layout>
          )}
          {!authState.isLoggedIn && (
            <Switch>
              <Route path="/auth">
                <Auth />
              </Route>
              <Route exact path="/">
                <Redirect to="/auth" />
              </Route>
              <Redirect
                to={{
                  pathname: "/auth",
                  search: returnTo ? `returnTo=${returnTo}` : undefined,
                }}
              />
            </Switch>
          )}
          <ToastContainer position="top-right" />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
