import React, { FC } from "react";
import Typography from "@material-ui/core/Typography";

type HeadingProps = {
  breadcrumbs?: string;
  title: string;
  left?: React.ReactChild;
};
const Heading: FC<HeadingProps> = ({ breadcrumbs, left, title, children }) => {
  return (
    <div className="heading">
      <div className="left">
        {breadcrumbs}
        <Typography variant="h3">{title}</Typography>
        {left}
      </div>
      <div className="right">{children}</div>
    </div>
  );
};

export default Heading;
