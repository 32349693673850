import Fab from '@material-ui/core/Fab'
import IconReload from '@material-ui/icons/Replay'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles({
  root: {
    marginLeft: 10,
    marginRight: 10
  }
}, { name: 'HeadingRefreshButton' })

export default function HeadingRefreshButton(props) {
  const classes = useStyles()
  return (
    <Fab classes={classes} aria-label='Reload' component='span' variant='round' {...props}>
      <IconReload />
    </Fab>
  )
}
