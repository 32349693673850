import { createContext } from "react";
import { AuthInfo } from "./auth.info";

type AuthContextType = {
  authInfo: AuthInfo;
  onLoginSuccess: (ai: AuthInfo, returnTo?: string) => void;
  onLogout: () => void;
  onUpdate: (firstName: string, lastName: string, email: string) => void;
};

const AuthContext = createContext<AuthContextType>({
  authInfo: {
    isLoggedIn: false,
  },
  onLoginSuccess: () => {},
  onLogout: () => {},
  onUpdate: () => {},
});

export const AuthProvider = AuthContext.Provider;
export default AuthContext;
