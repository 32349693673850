import Button from "@material-ui/core/Button";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useState } from "react";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import authService from "../../services/auth.service";
import { AuthContext } from "../../context/auth";

const useStyles = makeStyles((theme) => ({
  authCont: {
    padding: theme.spacing(4, 8),
    marginTop: theme.spacing(12),
    width: 440,
  },
  margin: {
    margin: theme.spacing(2),
  },
}));

const Auth = () => {
  const styles = useStyles();
  const history = useHistory();

  const { onLoginSuccess } = useContext(AuthContext);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [loading, setLoading] = useState(false);

  const showPassHandler = () => {
    setShowPass(!showPass);
  };

  const checkHandler = () => {
    setRemember(!remember);
  };

  const submitHandler = async (e: React.FormEvent) => {
    e.preventDefault();

    setLoading(true);

    try {
      const res = await authService.signIn(username, password);
      toast.success("You are successfully Signed In!");
      onLoginSuccess({
        isLoggedIn: true,
        email: username,
      });

      history.replace("/home");
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ValidatorForm onSubmit={submitHandler}>
      <Grid container justify="center">
        <Paper className={styles.authCont}>
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <Typography variant="h6">Sign In to your account</Typography>
            </Grid>
            <Grid item>
              <TextValidator
                label="Email"
                placeholder="Your email..."
                fullWidth
                variant="outlined"
                name="email"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setUsername(event.target.value);
                }}
                value={username}
                InputLabelProps={{ shrink: true }}
                validators={["required", "isEmail"]}
                errorMessages={["This field is required", "Email is not valid"]}
              />
            </Grid>
            <Grid item>
              <TextValidator
                label="Password"
                placeholder="Your password here.."
                fullWidth
                type={showPass ? "text" : "password"}
                variant="outlined"
                name="password"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setPassword(event.target.value)
                }
                value={password}
                InputLabelProps={{ shrink: true }}
                validators={["required"]}
                errorMessages={["This field is required"]}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      onClick={showPassHandler}
                      className="showPassword"
                      position="end"
                    >
                      {showPass ? <Visibility /> : <VisibilityOff />}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                checked={remember}
                onChange={checkHandler}
                control={<Switch />}
                label="Remember me"
              />
            </Grid>
            <Grid item>
              <div className="loginButtonWrap">
                <Button
                  disabled={loading}
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                >
                  login
                </Button>
                {loading && (
                  <div>
                    <CircularProgress size={24} />
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </ValidatorForm>
  );
};

export default Auth;
