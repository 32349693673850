import api from "../utils/api";

type SignInSignUpResponse = {
  message: string;
  token: string;
  data: {
    _id: string;
    firstName: string;
    lastName: string;
    email: string;
  };
};

type SignInResponse = {
  status: string;
  user: {
    token: string;
    data: {
      _id: string;
      firstName: string;
      lastName: string;
      email: string;
    };
  };
};

type SignUpRequestDto = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  organizationName: string;
};

class AuthService {
  isSignedIn = () => {
    return localStorage.getItem("hj_club_token") !== null;
  };

  signUp = async (data: SignUpRequestDto) => {
    const { firstName, lastName, email, password, organizationName } = data;

    const res: SignInSignUpResponse = await api.post(
      "jaychannel/auth/register",
      {
        firstName,
        lastName,
        email,
        password,
        organizationName,
      }
    );
    if (res) {
      localStorage.setItem("hj_club_token", res.token);
    }
  };

  signIn = async (email: string, password: string) => {
    const res: SignInResponse = await api.post("jaychannel/auth/sign-in", {
      email,
      password,
    });
    console.log(res);

    if (res) {
      localStorage.setItem("hj_club_token", res.user.token);
    }
  };

  resetPassword = async (email: string) => {
    await api.post("auth/reset-password", {
      email,
    });
  };

  newPassword = async (resetToken: string, password: string) => {
    await api.post("auth/new-password", {
      resetToken,
      password,
    });
  };

  signOut = () => {
    localStorage.removeItem("hj_club_token");
  };

  getUsers = () => {
    return api.get("/user");
  };

  getUser = (userId: string) => {
    return api.get(`/user/${userId}`);
  };
}

export default new AuthService();
