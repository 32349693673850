import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import imgLogo from "../../../assets/images/logo.svg";
import React, { FC, useContext } from "react";
import VideoLibraryOutlinedIcon from "@material-ui/icons/VideoLibraryOutlined";
import PlayCircleFilledOutlinedIcon from "@material-ui/icons/PlayCircleFilledOutlined";

import { makeStyles } from "@material-ui/core/styles";
import { AuthContext } from "../../../context/auth";
import { Link, useLocation } from "react-router-dom";

type LayoutNavProps = {};

const useStyles = makeStyles(
  {
    root: {
      color: "var(--color-gray)",
    },
  },
  { name: "LayoutNavEmail" }
);

const LayoutNav: FC<LayoutNavProps> = () => {
  const classes = useStyles();
  const { authInfo } = useContext(AuthContext);
  const location = useLocation();

  const menuActive = location.pathname.split("/")[1] || "home";

  return (
    <Grid className="layoutNav">
      <Grid className="user">
        <Typography variant="subtitle2">{authInfo.firstName}</Typography>
        <Typography variant="subtitle2" classes={classes}>
          {authInfo.email}
        </Typography>
        <Avatar
          variant="square"
          className="image"
          src={
            authInfo.imageUrl !== undefined && authInfo.imageUrl !== ""
              ? process.env.REACT_APP_IMAGE_URL + authInfo.imageUrl
              : undefined
          }
        >
          {authInfo.firstName}
        </Avatar>
      </Grid>
      <Grid className="top">
        <div className="logo">
          <img src={imgLogo} alt="HealthJay" />
        </div>
        <div className="appTitle">HEALTHJAY</div>
      </Grid>

      <ul className="sidebar-menu">
        <li>
          <div className="sidebar-title">JAYCHANNEL EDITOR</div>
        </li>
        <li className={menuActive === "library" ? "active" : ""}>
          <Link
            to={"/library"}
            className={menuActive === "library" ? "active" : ""}
          >
            <VideoLibraryOutlinedIcon />
            &nbsp;&nbsp;&nbsp;&nbsp;Video Library
          </Link>
        </li>
        <li className={menuActive === "channel" ? "active" : ""}>
          <Link
            to={"/channel"}
            className={menuActive === "channel" ? "active" : ""}
          >
            <PlayCircleFilledOutlinedIcon />
            &nbsp;&nbsp;&nbsp;&nbsp;Video Channel
          </Link>
        </li>
      </ul>
    </Grid>
  );
};

export default LayoutNav;
