import Icon from "@mdi/react";
import { FC, useEffect, useState } from "react";
import { camelCase } from "change-case";

type IconLoaderProps = {
  name: string;
};

const IconLoader: FC<IconLoaderProps> = ({ name }) => {
  const [iconLoaded, setIconLoaded] = useState(false);
  const [iconName, setIconName] = useState("");

  useEffect(() => {
    import("@mdi/js").then((results) => {
      // @ts-ignore
      setIconName(results[camelCase(`mdi-${name}`)]);
      setIconLoaded(true);
    });
  }, [name]);

  return !iconLoaded ? <></> : <Icon path={iconName} size={1} />;
};

export default IconLoader;
