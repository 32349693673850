import axios, { AxiosError, AxiosResponse } from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: "json",
});

api.interceptors.request.use((request) => {
  request.headers.Accept = "application/json";

  const jwt = localStorage.getItem("hj_club_token");
  if (jwt) {
    // Automatically attach JWT for authenticated API requets
    request.headers.Authorization = `Bearer ${jwt}`;
  }

  if (request.method === "POST" || request.method === "PUT") {
    // Let the backend know we're sending JSON data
    request.headers["Content-Type"] = "application/json";
  }

  return request;
});

api.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      localStorage.removeItem("hj_club_token");
      window.location.href = "/auth/sign-in";
      return Promise.reject(new Error("Unauthorized"));
    }
    return Promise.reject(error.response?.data || error);
  }
);

export default api;
