import React, { FC } from "react";
import Grid from "@material-ui/core/Grid";
import LayoutNav from "./LayoutNav";
import LayoutTopBar from "./LayoutTopbar";

const Layout: FC = ({ children }) => {
  return (
    <Grid className="layout">
      <LayoutNav />
      <Grid className="layoutMain">
        <Grid className="layoutContent">{children}</Grid>
        <LayoutTopBar />
      </Grid>
    </Grid>
  );
};

export default Layout;
