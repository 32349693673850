import { Fab, Grid, makeStyles } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { useEffect, useState } from "react";
import Heading from "../components/Layout/Heading";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import VideoCam from "@material-ui/icons/Videocam";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import api from "../../utils/api";
import HeadingRefreshButton from "../components/Layout/HeadingRefreshButton";
import { Loader } from "../../components/Loader";
import { IconLoader } from "../../components/IconLoader";

type CategoryType = {
  name: string;
  icon: string;
};

const useStyles = makeStyles({
  table: {
    minWidth: 512,
  },
  tableHead: {
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.87)",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.87)",
  },
  thumbImage: {
    height: 50,
  },
});

const Channel = () => {
  const styles = useStyles();

  const [categories, setCategories] = useState<CategoryType[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchResources = async () => {
    setLoading(true);
    try {
      const res = await api.get<
        Partial<CategoryType>[],
        Partial<CategoryType>[]
      >("jaychannel/category");
      setCategories(
        res.map((category) => ({
          name: category.name,
          icon: category.icon,
        })) as CategoryType[]
      );
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchResources();
  }, []);

  return (
    <Grid className="mainPage">
      {loading && <Loader />}
      <Heading
        title="Video Channel Categories"
        left={
          <HeadingRefreshButton
            onClick={() => {
              fetchResources();
            }}
          />
        }
      >
        <Fab color="default" onClick={() => {}}>
          <AddOutlinedIcon />
        </Fab>
      </Heading>

      <div className="main">
        <TableContainer component={Paper}>
          <Table aria-label="categories table" className={styles.table}>
            <TableHead>
              <TableRow>
                <TableCell
                  component="th"
                  variant="head"
                  className={styles.tableHead}
                  width={100}
                >
                  Icon
                </TableCell>
                <TableCell
                  component="th"
                  variant="head"
                  className={styles.tableHead}
                >
                  Title
                </TableCell>
                <TableCell
                  component="th"
                  variant="head"
                  className={styles.tableHead}
                  width={150}
                  align="center"
                >
                  Channel Videos
                </TableCell>
                <TableCell
                  component="th"
                  variant="head"
                  className={styles.tableHead}
                  width={100}
                  align="center"
                >
                  Edit
                </TableCell>
                <TableCell
                  component="th"
                  variant="head"
                  className={styles.tableHead}
                  width={100}
                  align="center"
                >
                  Delete
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categories.map((row) => (
                <TableRow key={row.name}>
                  <TableCell>
                    <IconLoader name={row.icon} />
                  </TableCell>
                  <TableCell scope="row" className={styles.title}>
                    {row.name}
                  </TableCell>
                  <TableCell align="center">
                    <VideoCam />
                  </TableCell>
                  <TableCell align="center">
                    <EditIcon />
                  </TableCell>
                  <TableCell align="center">
                    <DeleteIcon />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Grid>
  );
};

export default Channel;
