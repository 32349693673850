import { Fab, Grid, makeStyles } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { useEffect, useState } from "react";
import Heading from "../components/Layout/Heading";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import api from "../../utils/api";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import HeadingRefreshButton from "../components/Layout/HeadingRefreshButton";
import { toast } from "react-toastify";
import { Loader } from "../../components/Loader";

type ResourceType = {
  title: string;
  subTitle: string;
  url: string;
  thumb: string;
  type: string;
};

const useStyles = makeStyles({
  table: {
    minWidth: 512,
  },
  tableHead: {
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.87)",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.87)",
  },
  subTitle: {
    fontSize: 18,
    color: "rgba(0, 0, 0, 0.87)",
  },
  thumbImage: {
    height: 50,
  },
});

const Library = () => {
  const styles = useStyles();
  const [resources, setResources] = useState<ResourceType[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchResources = async () => {

    try {
      setLoading(true);
      const res = await api
        .get<Partial<ResourceType>[], Partial<ResourceType>[]>(
          "jaychannel/resource"
        );
      setResources(
        res.map((resource) => ({
          title: resource.title,
          subTitle: resource.subTitle,
          url: resource.url,
          thumb: resource.thumb,
          type: resource.type,
        })) as ResourceType[]
      );
      setLoading(false);
    } catch (e) {
      toast.error("Error fetching video library");
      setLoading(false);
    }
    
  };

  useEffect(() => {
    fetchResources();
  }, []);

  return (
    <Grid className="mainPage">
      {
        loading && <Loader />
      }
      
      <Heading
        title="Video Library"
        left={
          <HeadingRefreshButton
            onClick={() => {
              fetchResources();
            }}
          />
        }
      >
        <Fab color="default" onClick={() => {}}>
          <AddOutlinedIcon />
        </Fab>
      </Heading>

      <div className="main">
        <TableContainer component={Paper}>
          <Table aria-label="Library table" className={styles.table}>
            <TableHead>
              <TableRow>
                {["Thumbnail", "Title / Sub Title", "Edit", "Delete"].map(
                  (label) => (
                    <TableCell
                      key={label}
                      component="th"
                      variant="head"
                      className={styles.tableHead}
                    >
                      {label}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {resources.map((row) => (
                <TableRow key={row.thumb}>
                  <TableCell width={100}>
                    <img
                      src={row.thumb}
                      alt={row.title}
                      className={styles.thumbImage}
                    />
                  </TableCell>
                  <TableCell scope="row">
                    <div className={styles.title}>{row.title}</div>
                    <div className={styles.subTitle}>{row.subTitle}</div>
                  </TableCell>
                  <TableCell width={75}>
                    <EditIcon />
                  </TableCell>
                  <TableCell width={75}>
                    <DeleteIcon />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Grid>
  );
};

export default Library;
