import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { AuthContext } from '../../../context/auth';
import { Person } from "@material-ui/icons";
import authService from '../../../services/auth.service';

export default function LayoutTopBar() {
  const { authInfo, onLogout } = useContext(AuthContext);
  const history = useHistory();

  const handleLogout = () => {
    authService.signOut();
    onLogout();
    history.replace("/login");
  }

  return (
    <Grid className='layoutTopBar'>
      <Typography variant='h2' className='center-name'>{authInfo.firstName} </Typography>
      {/* <RoleChecker role={auth.role} allowed={[ROLE_SUPER_ADMIN, ROLE_ADMIN]}>
        <UpdateHexnode />
      </RoleChecker> */}
      <Button size='small' onClick={handleLogout}><Person />LOGOUT</Button>
    </Grid>
  )
}
